<nav class="navbar sticky-top navbar-blue bg-light">
  <div class="d-flex">
    <span class="oi oi-x mr-3" title="icon close" aria-hidden="true" (click)="handleCancelAddPatientClick()"></span>
    <h5 class="text-center">Neuer Patient</h5>
  </div>
</nav>

<div class="container">
  <div class="form-group">
    <input
      type="email"
      placeholder="E-Mail"
      class="form-control mr-sm-2"
      required
      [(ngModel)]="email"
      #emailInput="ngModel"
      >
  </div>
  
  <div *ngIf="emailInput.invalid && (emailInput.touched)" class="alert alert-danger">
    <div *ngIf="emailInput.errors.required">
      E-Mail ist erforderlich.
    </div>
  </div>

  <div class="form-group">
    <input
      type="text"
      placeholder="Vorname (optional)"
      class="form-control mr-sm-2"
      [(ngModel)]="firstName"
      #firstNameInput="ngModel"
      >
  </div>

  <div class="form-group">
    <input
      type="text"
      placeholder="Nachname (optional)"
      class="form-control mr-sm-2"
      [(ngModel)]="lastName"
      #lastNameInput="ngModel"
      >
  </div>

  <div class="form-group">
    <input 
      type="date"
      placeholder="Geburtsdatum (optional)"
      class="form-control mr-sm-2"
      [(ngModel)]="birthday"
      #birthdayInput="ngModel">
  </div>
  
  <app-button [disabled]="emailInput.invalid" (handleClick)="handleAddPatient()"> Hinzufügen</app-button>
</div>