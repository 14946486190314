import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ADD_PATIENT_ROUTER_PARAM, PATIENT_DETAILS_ROUTER_PARAM } from 'src/app/constants';
import { Patient } from 'src/app/model/patient.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent {

  patients$: Observable<Patient[]> = this.apiService.getPatients();
  searchTerm: string;

  constructor(private apiService: ApiService, private router: Router) { }

  handlePatientClick(patientId: string) {
    this.router.navigate([`${PATIENT_DETAILS_ROUTER_PARAM}/${patientId}`]);
  }

  handleAddPatientClick() {
    this.router.navigate([`${ADD_PATIENT_ROUTER_PARAM}`]);
  }
}
