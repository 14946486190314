<ng-container *ngIf="patient$ | async as patient">
  <ng-container *ngIf="responseSet$ | async as responseSet">
    <ng-container *ngIf="questionnaire$ | async as questionnaire">
      <nav class="navbar sticky-top navbar-blue bg-light">
        <div class="d-flex">
          <span
            class="oi oi-chevron-left mr-3"
            title="icon close"
            aria-hidden="true"
            (click)="handlePatientDetailsClick(patient.id)"
          ></span>
          <h5 class="text-center">
            {{ patient.firstName }} {{ patient.lastName }}
          </h5>
        </div>
      </nav>

      <div class="container">
        <div id="accordion">
          <div class="card" *ngFor="let num of numbers; let i = index">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                  aria-expanded="false"
                  [attr.aria-controls]="'collapse' + i"
                >
                  <div class="d-flex">
                    <span class="pl-3">{{ i + 1 | number: "2.0-0" }}</span>
                    <span class="pr-3 ml-auto">
                      {{
                        i >= 28
                          ? questionnaire.choiceSets[
                              questionnaire.questions[i].choiceSetId
                            ].choices[responseSet.answers[i].choiceId]
                              .valueNumber + " von 7"
                          : questionnaire.choiceSets[
                              questionnaire.questions[i].choiceSetId
                            ].choices[responseSet.answers[i].choiceId].label
                      }}
                    </span>
                  </div>
                </button>
              </h5>
            </div>
            <div
              id="collapse{{ i }}"
              class="collapse"
              [attr.aria-labelledby]="'heading' + i"
              data-parent="#accordion"
            >
              <div class="card-body">
                {{ questionnaire.questions[i].label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
