import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_BASE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(public client: HttpClient) { }

  public getLoginUserWithEmailSuccess(email: string, password: string): Observable<any> {
    const loginData = {
      email,
      password,
    };

    return this.client.post<any>(`${API_BASE}/login`, loginData);
  }

  public getRegisterNewUserWithEmailSuccess(email: string, password: string): Observable<any> {
    const signUpData = {
      email,
      password,
    };

    return this.client.post<any>(`${API_BASE}/signUp`, signUpData);
  }

  public getLogOutSuccess(): Observable<any> {
    return this.client.get<any>(`${API_BASE}/logout`);
  }
}
