import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ButtonComponent } from './components/button/button.component';
import { LoginComponent } from './components/login/login.component';
import { PatientAddComponent } from './components/patients/patient-add/patient-add.component';
import { PatientDetailsComponent } from './components/patients/patient-details/patient-details.component';
import { PatientListComponent } from './components/patients/patient-list/patient-list.component';
import { ResponseSetDetailsComponent } from './components/responseSets/response-set-details/response-set-details.component';
import { ApiService } from './services/api.service';
import { IsLoggedInGuard } from './services/is-logged-in-guard.service';
import { LoginService } from './services/login.service';
import { PersistanceService } from './services/persistance.service';
import { FilterPipe } from './utilities/pipes/filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    LoginComponent,
    PatientListComponent,
    PatientDetailsComponent,
    FilterPipe,
    PatientAddComponent,
    ResponseSetDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    LoginService,
    ApiService,
    PersistanceService,
    IsLoggedInGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
