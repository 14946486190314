<button
  *ngIf="!type"
  #btn
  class="btn-ripple animated fadeIn"
  (click)="onClick(btn)"
  [type]="submit ? 'submit' : 'button'"
  [disabled]="disabled"
  [title]="title"
>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</button>

<button
  *ngIf="type === 'filled'"
  #btn
  class="filled btn-ripple animated fadeIn"
  (click)="onClick(btn)"
  [type]="submit ? 'submit' : 'button'"
  [disabled]="disabled"
  [title]="title">
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</button>

<button
  *ngIf="type === 'stroked'"
  #btn
  class="stroked btn-ripple animated fadeIn"
  (click)="onClick(btn)"
  type="button"
  [disabled]="disabled"
  [title]="title"
>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</button>


<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>