import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  @Input()
  disabled: boolean;

  @Input()
  type: undefined | 'filled' | 'stroked';

  @Input()
  submit: false;

  @Input()
  disabledMessage: string;

  @Input()
  toolTip: string;

  @Output()
  private handleClick = new EventEmitter<any>();

  constructor() {}

  public onClick(button) {
    if (!this.disabled) {
      setTimeout(() => {
        button.blur();
        this.handleClick.emit();
      }, 500);
    }
  }

  get title(): string {
    if (this.disabled && this.disabledMessage) {
      return this.disabledMessage;
    }
    if (!this.disabled && this.toolTip) {
      return this.toolTip;
    }
    return '';
  }
}
