import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LOGIN_ROUTER_PARAM, LOGIN_STATUS_PERSISTANCE_KEY } from '../constants';

import { PersistanceService } from './persistance.service';

@Injectable()
export class IsLoggedInGuard implements CanActivate {
  constructor(private router: Router, private persistance: PersistanceService) {}

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const loggedIn = this.persistance.get(LOGIN_STATUS_PERSISTANCE_KEY);

    if (loggedIn) {
      return true;
    } else {
      this.router.navigate([LOGIN_ROUTER_PARAM]);
      return false;
    }
  }
}
