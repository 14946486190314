import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PATIENT_DETAILS_ROUTER_PARAM, RESPONSE_SET_ID_ROUTER_PARAM } from 'src/app/constants';
import { Patient } from 'src/app/model/patient.model';
import { Questionnaire } from 'src/app/model/questionnaire.model';
import { ResponseSet } from 'src/app/model/response-set.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-response-set-details',
  templateUrl: './response-set-details.component.html',
  styleUrls: ['./response-set-details.component.scss'],
})
export class ResponseSetDetailsComponent implements OnInit {

  responseSet$: Observable<ResponseSet>;
  questionnaire$: Observable<Questionnaire>;
  patient$: Observable<Patient>;
  numbers = new Array<number>(30);

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.getResponseSetData();
  }

  getResponseSetData() {
    this.route.params.pipe(
      take(1),
    ).subscribe(params => {
      const responseSetId = params[RESPONSE_SET_ID_ROUTER_PARAM];
      this.responseSet$ = this.apiService.getResponseSet(responseSetId);
      this.responseSet$.pipe(
        take(1),
      ).subscribe((responseSet: ResponseSet) => {
        this.patient$ = this.apiService.getPatient(responseSet.patientId);
        this.questionnaire$ = this.apiService.getQuestionnaire(responseSet.questionnaireId);
      });
    });
  }

  handlePatientDetailsClick(patientId: string) {
    this.router.navigate([`${PATIENT_DETAILS_ROUTER_PARAM}/${patientId}`]);
  }
}
