<ng-container *ngIf="patient$ | async as patient">
  <nav class="navbar sticky-top navbar-blue bg-light">
    <div class="d-flex">
      <span class="oi oi-x mr-3" title="icon close" aria-hidden="true" (click)="handleBackToListClick()"></span>
      <h5 class="text-center">{{patient.firstName}} {{patient.lastName}}</h5>
    </div>
  </nav>
  
  <div class="container">
    <!-- TODO: Graphic of patient development here -->
  
    <h3 class="mt-3">Befragungen</h3>
    <ng-container *ngIf="responseSets$ | async as responseSets">
      <ul class="list-group">
        <li *ngFor="let responseSet of responseSets" class="list-group-item" (click)="handleResponseSetClick(responseSet.id)">{{responseSet.answers[29].answeredAt | date: 'dd.MM.yyyy'}}</li>
      </ul>
    </ng-container>
  </div>
</ng-container>