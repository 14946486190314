<nav class="navbar sticky-top navbar-blue bg-light">
  <div class="d-flex">
    <span class="oi oi-plus mr-3" title="icon plus" aria-hidden="true" (click)="handleAddPatientClick()"></span>
    <form class="form-inline">
        <input 
          #search
          name="search"
          class="form-control mr-sm-2"
          type="search"
          placeholder="Suche"
          aria-label="Search"
          [(ngModel)]="searchTerm"
          >
    </form>
  </div>
</nav>

<ng-container *ngIf="patients$ | async as patients">
  <ul class="list-group list-group-flush">
    <li
      *ngFor="let patient of patients | filter: searchTerm"
      class="list-group-item"
      (click)="handlePatientClick(patient.id)"
      >
      {{patient.firstName ? patient.firstName : 'Fehlende Daten (' + patient.mail + ')'}} {{patient.lastName}}
    </li>
  </ul>
</ng-container>
