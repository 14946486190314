<div class="container">
  <h1>{{registering ? 'Register' : 'Login'}}</h1>
  <div>
    <div class="form-group">
      <input
        type="email"
        placeholder="E-Mail"
        class="form-control mr-sm-2"
        required
        [(ngModel)]="email"
        #emailInput="ngModel"
        >
    </div>
    
    <div *ngIf="emailInput.invalid && (emailInput.touched)" class="alert alert-danger">
      <div *ngIf="emailInput.errors.required">
        E-Mail ist erforderlich.
      </div>
    </div>
  </div>
  
  <div>
    <div class="form-group">
      <input
        type="password"
        placeholder="Passwort"
        class="form-control mr-sm-2"
        required
        [(ngModel)]="password"
        #passwordInput="ngModel"
        >
    </div>
    
    <div *ngIf="passwordInput.invalid && (passwordInput.touched)" class="alert alert-danger">
      <div *ngIf="passwordInput.errors.required">
        Password ist erforderlich.
      </div>
    </div>
  </div>
  
  <div *ngIf="registering">
    <div class="form-group">
      <input
        type="password"
        placeholder="Passwort bestätigen"
        class="form-control mr-sm-2"
        required
        [(ngModel)]="passwordConfirm"
        #passwordConfirmation="ngModel"
        >
    </div>
  
    <div *ngIf="passwordConfirmation.invalid && (passwordConfirmation.touched) || passwordConfirmation.value != passwordInput.value" class="alert alert-danger">
      <div *ngIf="passwordConfirmation.errors.required">
        Die Passwort Bestätigung ist erforderlich.
      </div>
      <div *ngIf="passwordConfirm != password">
        Die Passwörter müssen übereinstimmen.
      </div>
    </div>
  </div>
  
  <app-button
    *ngIf="!registering"
    (handleClick)="handleLoginClick()"
    [disabled]="emailInput.invalid || passwordInput.invalid"
    >
    Login
  </app-button>
  
  <app-button
    *ngIf="registering"
    (handleClick)="handleRegisterClick()"
    [disabled]="emailInput.invalid || passwordInput.invalid || passwordConfirm != passwordInput.value"
    type="stroked"
    >
    Register
  </app-button>
  
  <button 
    class="link-button"
    (click)="handleToggleRegisterClick()"
    >
    {{registering ? existingAccount : noExistingAccount}}
  </button>
</div>