import { Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { PatientAddComponent } from './components/patients/patient-add/patient-add.component';
import { PatientDetailsComponent } from './components/patients/patient-details/patient-details.component';
import { PatientListComponent } from './components/patients/patient-list/patient-list.component';
import { ResponseSetDetailsComponent } from './components/responseSets/response-set-details/response-set-details.component';
import {
  ADD_PATIENT_ROUTER_PARAM,
  LOGIN_ROUTER_PARAM,
  PATIENT_DETAILS_ROUTER_PARAM,
  PATIENT_ID_ROUTER_PARAM,
  PATIENT_LIST_ROUTER_PARAM,
  RESPONSE_SET_ID_ROUTER_PARAM,
  RESPONSE_SET_ROUTER_PARAM,
} from './constants';
import { IsLoggedInGuard } from './services/is-logged-in-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: LOGIN_ROUTER_PARAM,
    pathMatch: 'full',
  },
  {
    path: LOGIN_ROUTER_PARAM,
    component: LoginComponent,
  },
  {
    path: PATIENT_LIST_ROUTER_PARAM,
    canActivate: [IsLoggedInGuard],
    component: PatientListComponent,
  },
  {
    path: `${PATIENT_DETAILS_ROUTER_PARAM}/:${PATIENT_ID_ROUTER_PARAM}`,
    canActivate: [IsLoggedInGuard],
    component: PatientDetailsComponent,
  },
  {
    path: `${ADD_PATIENT_ROUTER_PARAM}`,
    canActivate: [IsLoggedInGuard],
    component: PatientAddComponent,
  },
  {
    path: `${RESPONSE_SET_ROUTER_PARAM}/:${RESPONSE_SET_ID_ROUTER_PARAM}`,
    canActivate: [IsLoggedInGuard],
    component: ResponseSetDetailsComponent,
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTER_PARAM,
    pathMatch: 'full',
  },
];
