import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_BASE } from '../constants';
import { Patient } from '../model/patient.model';
import { AddPatientRequest } from '../model/requests/add-patient-request.model';
import { ResponseSet } from '../model/response-set.model';
import { Questionnaire } from '../model/questionnaire.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(public client: HttpClient) { }

  public addPatient(request: AddPatientRequest): Observable<boolean> {
    return this.client.post<boolean>(`${API_BASE}/patients/add`, request);
  }

  public getPatients(): Observable<Patient[]> {
    return this.client.get<Patient[]>(`${API_BASE}/patients`);
  }

  public getPatient(patientId: string): Observable<Patient> {
    return this.client.get<Patient>(`${API_BASE}/patients/${patientId}`);
  }

  public getPatientsResponseSets(patientId: string): Observable<ResponseSet[]> {
    return this.client.get<ResponseSet[]>(`${API_BASE}/patients/responseSets/${patientId}`);
  }

  public getResponseSet(responseSetId: string): Observable<ResponseSet> {
    return this.client.get<ResponseSet>(`${API_BASE}/responseSets/${responseSetId}`);
  }

  public getQuestionnaire(questionnaireId: string): Observable<Questionnaire> {
    return this.client.get<Questionnaire>(`${API_BASE}/questionnaires/${questionnaireId}`);
  }
}
