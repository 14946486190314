import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from 'src/app/model/patient.model';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(patients: any[], search: string): Patient[] {
    if (!patients) { return []; }
    if (!search) { return patients; }

    search = search.toLowerCase();

    return patients.filter((patient: Patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`;

      return fullName.toLowerCase().includes(search);
    });
  }
}
