import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PATIENT_ID_ROUTER_PARAM, PATIENT_LIST_ROUTER_PARAM, RESPONSE_SET_ROUTER_PARAM } from 'src/app/constants';
import { Patient } from 'src/app/model/patient.model';
import { ResponseSet } from 'src/app/model/response-set.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit {

  patient$: Observable<Patient>;
  responseSets$: Observable<ResponseSet[]>;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getPatientData();
  }

  getPatientData() {
    this.route.params.pipe(
      take(1),
    ).subscribe(params => {
      const patientId = params[PATIENT_ID_ROUTER_PARAM];
      this.patient$ = this.apiService.getPatient(patientId);
      this.responseSets$ = this.apiService.getPatientsResponseSets(patientId);
    });
  }

  handleBackToListClick() {
    this.router.navigate([PATIENT_LIST_ROUTER_PARAM]);
  }

  handleResponseSetClick(responseSetId: string) {
    this.router.navigate([`${RESPONSE_SET_ROUTER_PARAM}/${responseSetId}`]);
  }
}
