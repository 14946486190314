import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { PersistanceService } from 'src/app/services/persistance.service';

import {
  LOGIN_STATUS_PERSISTANCE_KEY,
  LOGIN_WITH_EXISTING_ACCOUNT,
  LOGIN_WITHOUT_EXISTING_ACCOUNT,
  PATIENT_LIST_ROUTER_PARAM,
} from '../../constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  passwordConfirm: string;
  registering = false;

  existingAccount = LOGIN_WITH_EXISTING_ACCOUNT;
  noExistingAccount = LOGIN_WITHOUT_EXISTING_ACCOUNT;

  constructor(private loginService: LoginService, private router: Router, private persistance: PersistanceService) { }

  ngOnInit() {
    this.persistance.set(LOGIN_STATUS_PERSISTANCE_KEY, false);
  }

  handleLoginClick() {
    this.loginService.getLoginUserWithEmailSuccess(this.email, this.password).pipe(
      take(1),
    ).subscribe((loginSuccess: any) => {
      if (loginSuccess === true) {
        this.persistance.set(LOGIN_STATUS_PERSISTANCE_KEY, loginSuccess);
        this.router.navigate([PATIENT_LIST_ROUTER_PARAM]);
      } else {
        console.log('this happened: ' + loginSuccess);
      }
    });
  }

  handleRegisterClick() {
    this.loginService.getRegisterNewUserWithEmailSuccess(this.email, this.password).pipe(
      take(1),
    ).subscribe((registrationSuccess: any) => {
      if (registrationSuccess === true) {
        this.persistance.set(LOGIN_STATUS_PERSISTANCE_KEY, registrationSuccess);
        this.router.navigate([PATIENT_LIST_ROUTER_PARAM]);
      } else {
        console.log('this happened: ' + registrationSuccess);
      }
    });
  }

  handleToggleRegisterClick() {
    this.registering = !this.registering;
  }
}
