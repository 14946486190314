export const LOGIN_ROUTER_PARAM = 'login';
export const PATIENT_LIST_ROUTER_PARAM = 'patient-list';
export const PATIENT_DETAILS_ROUTER_PARAM = 'patient-details';
export const PATIENT_ID_ROUTER_PARAM = 'patientId';
export const ADD_PATIENT_ROUTER_PARAM = 'add-patient';
export const RESPONSE_SET_ROUTER_PARAM = 'responseSet';
export const RESPONSE_SET_ID_ROUTER_PARAM = 'responseSetId';

export const API_BASE = 'https://europe-west1-esprio-be4e6.cloudfunctions.net/api';

export const LOGIN_WITH_EXISTING_ACCOUNT = 'Haben Sie schon einen Account ?';
export const LOGIN_WITHOUT_EXISTING_ACCOUNT = 'Haben Sie sich noch nicht registriert ?';

export const LOGIN_STATUS_PERSISTANCE_KEY = 'loggedIn';
