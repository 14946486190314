import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PATIENT_LIST_ROUTER_PARAM } from 'src/app/constants';
import { AddPatientRequest } from 'src/app/model/requests/add-patient-request.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.scss'],
})
export class PatientAddComponent {

  email: string;
  firstName: string;
  lastName: string;
  birthday: string;

  constructor(private apiService: ApiService, private router: Router) { }

  handleCancelAddPatientClick() {
    this.router.navigate([PATIENT_LIST_ROUTER_PARAM]);
  }

  handleAddPatient() {
    const addPatientRequest: AddPatientRequest = {
      mail: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      birthday: this.birthday,
    };

    this.apiService.addPatient(addPatientRequest).pipe(
      take(1),
    ).subscribe((success: boolean) => {
      if (success) {
        this.router.navigate([PATIENT_LIST_ROUTER_PARAM]);
      } else {
        console.log(success);
      }
    });
  }
}
